import '../app.css';
import '../styles.css';
import React from "react";

function App() {
    return (
        <div className="p-20">
            <h1 className="text-4xl font-bold">Sleeping Elephant</h1>
        </div>
    );
}

export default App;
