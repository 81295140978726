import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div className="p-20 space-y-4 flex flex-col items-center justify-center h-screen">
            <h1 className="font-bold text-3xl">Oops!</h1>
            <p className="text-lg">Sorry, an unexpected error has occurred.</p>
            <p className="text-slate-500">
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}
