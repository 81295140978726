import SideBar from "../components/side-bar/SideBar";
import React from "react";

function Wrapper({children}) {
    return (
        <div className="grid grid-cols-[1fr_3fr]">
            <SideBar/>
            <div>
                {children}
            </div>
        </div>
    );
}

export default Wrapper;
