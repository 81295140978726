import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ErrorPage from "./error-page";
import App from "./routes/App";
import Tabs from "./routes/Tabs";
import Wrapper from "./routes/Wrapper";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Wrapper><App /></Wrapper>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/tabs",
        element: <Wrapper><Tabs /></Wrapper>,
    }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

reportWebVitals();
