import Item from "./Item";

function SideBar() {
    return (
        <div className="p-2 mt-6">
            <ul className="mt-4 space-y-2">
                <Item name="Home" link="/" />
                <Item name="Tabs" link="/tabs" />
            </ul>
        </div>
    )
}

export default SideBar;