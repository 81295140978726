import React from 'react';
import PageTabButton from "./PageTabButton";

function PageTabs() {
    return (
        <div id="tabs.file_tabs" className="mt-6">
            <h3 className="text-3xl">Page Tabs</h3>

            <div className="my-6 flex">
                <div className="border-e border-black">&nbsp;</div>
                <div className="space-y-4 my-6">
                    <PageTabButton name="Starters" bg="bg-[#ccd5ae]"/>
                    <PageTabButton name="Mains" bg="bg-[#e9edc9]"/>
                    <PageTabButton name="Desserts" bg="bg-[#fefae0]"/>
                    <PageTabButton name="Drinks" bg="bg-[#faedcd]"/>
                </div>
            </div>
        </div>
    )
}

export default PageTabs;
